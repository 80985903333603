import { useState } from "react"
import useDayChangeEffect from "./useDayChangeEffect"

function useRelativeDate(isoDate?: string | null): string | null | undefined {
  const date = isoDate ? new Date(isoDate) : null
  const [relativeDate, setRelativeDate] = useState(getRelativeDate(date))
  useDayChangeEffect(() => {
    setRelativeDate(getRelativeDate(date))
  })
  return relativeDate
}

function getRelativeDate(
  date?: Date | null,
  locale: string = navigator.language
): string | null {
  if (date == null) return null
  const now = new Date()
  if (date.toDateString() === now.toDateString()) {
    return date.toLocaleTimeString(locale, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  }
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleDateString(locale, { month: "short", day: "numeric" })
  }
  return date.toLocaleDateString(locale, {
    month: "short",
    day: "numeric",
    year: "numeric",
  })
}

export default useRelativeDate
