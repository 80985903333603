import { useEffect, useState } from "react"

const useDayChangeEffect = (callback: () => void) => {
  const [currentDay, setCurrentDay] = useState(new Date().getDate())

  useEffect(() => {
    const checkDayChange = () => {
      const now = new Date()
      if (now.getDate() !== currentDay) {
        setCurrentDay(now.getDate())
        callback()
      }
    }

    const interval = setInterval(checkDayChange, 60 * 1000)

    return () => clearInterval(interval)
  }, [currentDay, callback])
}

export default useDayChangeEffect
